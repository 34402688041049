<template>
  <div>
    <div id="container" v-if="!active && status == ''">
      <h1 class="text">
        TOPLANTI BİLGİLERİ KONTROL EDİLİYOR
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </h1>
    </div>
    <VideoChatSmsModal @response="handleData($event)" />
    <div id="container" v-if="!active">
      <h1 class="text">{{message}}</h1>
    </div>
    <vue-jitsi-meet
      v-if="active"
      style="height: 800px"
      ref="jitsiRef"
      domain="live.terapivitrini.com"
      :options="jitsiOptions"
    ></vue-jitsi-meet>
    <div class="connection-button" v-if="!panelStatus && this.$store.state.isLogin" @click="openDialog">
      Online Görüşme Kimlik Bilgisi
    </div>
    <div class="connection-info" v-if="panelStatus && this.$store.state.isLogin">
      <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Online Görüşme Kimlik Bilgisi</h3>
        <button class="btn btn-primary" @click="closeDialog">X</button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">Kullanıcı Adı</label>
              <div
                name="oldpassword"
                type="password"
                class="form-control"
              >{{res.username}}</div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <label class="form-label">Parola</label>
            <div class="input-group">
              <div v-if="!isShow" class="form-control">*******************</div>
              <div v-if="isShow" class="form-control">{{res.password}}</div>
              <div v-if="isShow" class="btn btn-white" title="kopyala" @click="copyItem"><i class="fa fa-copy"></i></div>
              <div
              v-if="!isShow"
              @click="showMe"
                name="password"
                type="password"
                class="btn btn-primary"
              >Göster</div>
              <div
              v-if="isShow"
              @click="hideMe"
                name="password"
                type="password"
                class="btn btn-primary"
              >Gizle</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<style scoped>
#container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 500px;
}
.connection-info{
  width: 300px;
  height: 150px;
  position:absolute;
  bottom: 10px;
  left: 10px;
  background: white;
}
.connection-button{
  width: 100px;
    height: 100px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    color: white;
    background: #bb7494;
    padding: 10px;
    border-radius: 60px;
    cursor: pointer;
}
.text {
  color: #000;
  padding: 20px;
  text-align: center;
}
</style>
<script>
import { JitsiMeet } from "@mycure/vue-jitsi-meet";
import VideoChatSmsModal from "./Components/VideoChatSmsModal.vue";
export default {
  metaInfo() {
    return {
      title: "Terapi Vitrini - Online Görüşme",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Terapi Vitrini Online Görüşme - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
        },
      ],
    };
  },
  components: { VideoChatSmsModal },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    let info = {
      id: this.$route.params.id,
    };
    if(!this.$store.state.isLogin)
    {
      this.$store.dispatch("videoCallingGetInfo", info).then((value) => {
        this.status = value.type;
        if (value.type == "success") {
          if (value.message == "SCCxMSND") {
            this.$modal.show("smsmodal");
          }
          if(value.message=="SCCxMSND"){
            this.message="SMS GÖNDERİLDİ"
          }
          if (value.message == "SCCxVCA") {
            this.roomName = value.data.roomName;
            this.username = value.data.username;
            this.email = value.data.email;
            this.active = true;
          }
        }
        if(value.type=='error'){
          if(value.message=="ERRxMRPE"){
            this.message="ÇOK FAZLA SMS TALEP EDİLDİ. BİRAZ BEKLEYİP YENİDEN DENEYİN"
          }
          if(value.message=="ERRxCNW"){
            this.message="TOPLANTIYA ŞUAN ERİŞİLEMEZ"
          }
          if(value.message=="ERRxCLS"){
            this.message="TOPLANTI BULUNAMADI"
          }
        }
      });
    } else {
      this.$store.dispatch("ownerMeetIdInfo").then((value) => {
        this.res=value;
    })
      this.$store.dispatch("videoCallingGetInfoToken", info).then((value) => {
        this.status = value.type;
        if (value.type == "success") {
          if (value.message == "SCCxMSND") {
            this.$modal.show("smsmodal");
          }
          if(value.message=="SCCxMSND"){
            this.message="SMS GÖNDERİLDİ"
          }
          if (value.message == "SCCxVCA") {
            this.roomName = value.data.roomName;
            this.username = value.data.username;
            this.email = value.data.email;
            this.active = true;
          }
        }
        if(value.type=='error'){
          if(value.message=="ERRxMRPE"){
            this.message="ÇOK FAZLA SMS TALEP EDİLDİ. BİRAZ BEKLEYİP YENİDEN DENEYİN"
          }
          if(value.message=="ERRxCNW"){
            this.message="TOPLANTIYA ŞUAN ERİŞİLEMEZ"
          }
          if(value.message=="ERRxCLS"){
            this.message="TOPLANTI BULUNAMADI"
          }
        }
      });
    }
  },
  components: {
    VueJitsiMeet: JitsiMeet,
    VideoChatSmsModal,
  },
  computed: {
    jitsiOptions() {
      return {
        appId: "vpaas-magic-cookie-a36217c8bbfd43d28cbd75093a31c9eb",
        roomName: this.roomName,
        noSSL: false,
        userInfo: {
          email: this.email,
          displayName: this.username,
        },
        configOverwrite: {
          enableNoisyMicDetection: true,
        },
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: true,
          SHOW_WATERMARK_FOR_GUESTS: true,
          SHOW_CHROME_EXTENSION_BANNER: true,
        },
        onload: this.onIFrameLoad,
      };
    },
  },
  data() {
    return {
      active: false,
      roomName: "",
      status: "",
      username: "",
      email: "",
      message:"",
      res:null,
      isShow:false,
      panelStatus:false,
    };
  },
  methods: {
    closeDialog(){
      this.panelStatus=false;
    },
    openDialog(){
      this.panelStatus=true;
    },
    showMe(){
        this.isShow=true;
    },
    hideMe(){
        this.isShow=false;
    },
    copyItem(){
        navigator.clipboard.writeText(this.res.password);
        this.$vToastify.success("Şifre panoya koplayandı", "Başarılı!");
    },
    handleData(e) {
      console.log(e)
      this.roomName = e.data.roomName;
      this.username = e.data.username;
      this.email = e.data.email;
      this.active = true;
      this.$modal.hide("smsmodal");
    },
  },
  mounted() {
    
  },
};
</script>